@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"), url("./Flaticon.woff2") format("woff2"), url("./Flaticon.woff") format("woff"), url("./Flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: auto;
  font-style: normal;
  margin-left: 0;
}

.flaticon-mail-2:before {
  font-family: Flaticon !important;
  content: "\f100";
}

.flaticon-password:before {
  font-family: Flaticon !important;
  content: "\f101";
}

.flaticon-account:before {
  font-family: Flaticon !important;
  content: "\f102";
}
