/*
 *
 *   GULL - React Bootstrap Admin Template
 *
 *
*/

@import '../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../node_modules/react-datetime/css/react-datetime.css';
@import '../../../node_modules/rc-slider/assets/index.css';
@import '../../../node_modules/rc-tooltip/assets/bootstrap.css';
@import '../../../node_modules/react-quill/dist/quill.bubble.css';
@import '../../../node_modules/react-quill/dist/quill.snow.css';
@import '../../../node_modules/react-quill/dist/quill.core.css';
@import '../../../node_modules/react-vis/dist/style';
@import '../../../node_modules/ladda/dist/ladda.min.css';
@import '../../../node_modules/react-notifications/lib/notifications.css';
@import '../../../node_modules/sweetalert2/src/sweetalert2.scss';
// @import '../../../node_modules/react-image-crop/lib/ReactCrop.scss';
@import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../../../node_modules/@fullcalendar/timegrid/main.css';
@import '../../../node_modules/@fullcalendar/core/main.css';
@import '../../../node_modules/@fullcalendar/daygrid/main.css';
@import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
@import '../iconsmind/iconsmind.css';
@import '../flaticon/flaticon.css';
@import 'variables';

// @import 'themes/lite-blue';
@import 'themes/lite-purple.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'bootstrap-rtl.scss';
@import 'globals/globals';
